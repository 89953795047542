import { useEffect } from 'react';

// Hooks
import { useStateMachine } from 'little-state-machine';
import { useRouter } from 'next/router';
import useHasMount from '../../hooks/utils/useHasMount';
import useRouterEvents from '../../hooks/navigation/useRouterEvents';

// Components
import LottieLoader from './LottieLoader';

// Global state
import { updateNavigation, updateSession } from '../../../store/actions';

export default function PageLoader() {
  const {
    actions,
    state: { navigation, session, labels },
  } = useStateMachine({ updateNavigation, updateSession });

  const router = useRouter();

  const isMounted = useHasMount();

  function handleStart() {
    actions.updateNavigation({
      pageLoading: true,
    });
  }

  function handleStop() {
    actions.updateNavigation({ pageLoading: false });
  }

  useRouterEvents({ handleStart, handleStop });

  useEffect(() => {
    if (router.asPath.includes('/register') && session?.signInProgress) {
      actions.updateSession({ signInProgress: false });
    }
  }, [router]);

  if (!isMounted) return null;

  if (navigation?.pageLoading || session?.signInProgress) {
    return <LottieLoader loadingLabel={labels?.loadingLabel} />;
  }

  return null;
}
